.image-grid {
    margin: auto;
    display: grid;
    gap: 2%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 80%;
    height: 20%;
}

.image-item {
    width: 100%;
    height: 100%;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Media query to hide images on mobile devices */
@media (max-width: 600px) {
    .image-grid {
        display: none;
    }
}