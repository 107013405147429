.ticker-slide {
  width: 100%;
  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* Ensure overflow is hidden */
  position: fixed;
  top: 0;
  margin: 0;
  padding: 5px 0;
  z-index: 100;
  background: linear-gradient(90deg, rgba(153, 69, 255, 1), rgba(20, 241, 149, 1), rgba(153, 69, 255, 1));
  background-size: 700% 100%;
}

.ticker-slide h2 {
  word-spacing: 15rem;
}

.slideInOut {
  letter-spacing: 10px;
  display: inline-block;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  position: absolute;
  animation: slideInOut 8s linear infinite;
}

@keyframes slideInOut {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.rainbow-background-animated {
  background: linear-gradient(90deg, rgba(153, 69, 255, .75), rgba(20, 241, 149, .4), rgba(153, 69, 255, .75)), 0 0 / 200% 100%;
  color: transparent;
  animation: rainbow_animation 4s linear infinite;
  ;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {

  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

@media (max-width: 600px) {
  .slideInOut {
    animation: slideInOut 15s linear infinite;
  }
}