.hero {
    width: 100%;
    height: 100vh;
    background-image: '/assets/dotted-bg.png';
    background-repeat: repeat;
    background-position: center;
    background-color: #fff;
    display: flex !important;
    justify-content: center !important;
    overflow-x: clip;
}

.hero-wrapper {
    width: 90%;
    padding: 200px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hero h2 {
    text-align: center;
}

.hero-left {
    width: 50%;
}

.right-wrapper {
    display: flex;
    justify-content: center;
}

.hero-right {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.logo-animation {
    display: flex;
    justify-content: end;
    justify-content: center;
    align-items: center;
}

.logo-dancing {
    width: 70%;
    transform: scale(2);
    text-align: right;
}

.top-h1-text {
    text-align: right;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase !important;
    font-size: 5rem;
    margin: 0 0 0 -100px;
}

.rainbow_text_animated {
    background: linear-gradient(90deg, rgba(153, 69, 255, .75), rgba(20, 241, 149, .4), rgba(153, 69, 255, .75)), 0 0 / 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 4s linear infinite;
    background-size: 400% 100%;
}

.rainbow_text_animated1 {
    background: linear-gradient(90deg, rgba(255, 69, 69, 0.75), rgba(20, 31, 241, 0.4), rgba(153, 69, 255, .75)), 0 0 / 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 4s linear infinite;
    ;
    background-size: 400% 100%;
}

.rainbow-background-animated {
    background: linear-gradient(90deg, rgba(153, 69, 255, .75), rgba(20, 241, 149, .4), rgba(153, 69, 255, .75)), 0 0 / 200% 100%;
    color: transparent;
    animation: rainbow_animation 4s linear infinite;
    ;
    background-size: 400% 100%;
}

@keyframes rainbow_animation {

    0%,
    100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}

.bottom-h1-text {
    font-family: "Montserrat", sans-serif;
    text-align: right;
    text-transform: uppercase !important;
    font-size: 5rem;
    margin: 0;
}

.middle-area h4 {
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
}

.middle-area {
    text-align: center;
    padding-top: 50px;
}

.rainbow-line2 {
    width: 40%;
    height: 30px;
    animation: pulse 1s linear infinite;
}

.logo-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;
}

.logo-container-hero img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: 0 10px 10px #00000029;
    margin: 0 20px;
}

.logo-container-hero img:hover {
    transform: scale(1.2);
    transition: .5s ease;
    box-shadow: none;

}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .hero-wrapper {
        flex-direction: column;
    }

    .bottom-h1-text {
        font-size: 2rem;
        text-align: center;
    }

    .top-h1-text {
        font-size: 2rem;
        margin: 0;
        text-align: center;
    }

    .logo-animation {
        margin-top: 30%;
        display: flex;
        justify-content: center;
    }

    .hero-right {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .hero-left {
        width: 90%;
    }

    .logo-dancing {
        text-align: center;
        width: 200px;
        top: -50px;
        position: relative;
    }

    .middle-area h4 {
        font-size: 1.2rem;
    }

    .hero h2 {
        font-size: 1rem;
    }
}