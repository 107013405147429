.home-content {
    padding: 20px;
    font-family: "Montserrat", sans-serif;
}

.home-content p {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    line-height: 40px;
}

.home-content h1 {
    font-size: 4rem;
}

.logos2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    margin-top: 20px;
}

.logo-container2 {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

.logo-container2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.logo-container2:hover img {
    transform: scale(1.1);
    /* Zoom effect on hover */
}

.home-content img {
    width: 90%;
}

@media (max-width: 600px) {
    .home-content h1 {
        font-size: 2rem;
    }

    .home-content {
        text-align: center;
    }

    .home-content p {
        text-align: center;
        font-size: 1.2rem;
        line-height: 30px;
    }
}