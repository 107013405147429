.about-logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    /* Space between the logos */
    padding: 10px;
    /* Padding around the container */
    justify-items: center;
    align-items: center;
}

.logo-container {
    width: 150px;
    /* Adjust the size as needed */
    height: 150px;
    /* Adjust the size as needed */
    overflow: hidden;
    border-radius: 50%;
    /* Makes the container circular */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Adds a nice shadow effect */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    /* Background color for better appearance */
}

.logo-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the container */
    transition: transform 0.3s ease;
    /* Smooth zoom effect on hover */
}

.logo-container:hover img {
    transform: scale(1.1);
    /* Zoom effect on hover */
}

.logo-container2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the container */
    transition: transform 0.3s ease;
    /* Smooth zoom effect on hover */
}

.logo-container2:hover img {
    transform: scale(1.1);
    /* Zoom effect on hover */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .about-logos {
        grid-template-columns: repeat(2, 1fr);
        /* Change grid layout to 2 columns */
        grid-gap: 5px;
        /* Adjust gap between logos */
        padding: 5px;
        /* Adjust padding around the container */
    }

    .logo-container2 {
        display: none !important;
    }

    .logo-container {
        width: 80px;
        /* Adjust size for smaller screens */
        height: 80px;
        /* Adjust size for smaller screens */
    }
}